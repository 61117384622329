<template>
  <el-dialog v-model="shouldShow" title="Selecione os contatos para enviar">
    <el-loading v-loading="isLoading" v-if="isLoading"> </el-loading>
    <div v-else>
      <el-row type="flex" justify="space-between">
        <h4>Selecionar todos</h4>
        <el-checkbox
          size="medium"
          v-model="selectAll"
          @change="markOrUnmarkAllContacts()"
          class="my-auto"
        ></el-checkbox>
      </el-row>
      <el-row v-for="(contacts, key) in ContactGrouped" :key="key">
        <el-col>
          <el-divider class="margin-divider"></el-divider>
          <el-row type="flex" justify="space-between">
            <h4>{{ key }}</h4>
            <el-checkbox
              size="medium"
              v-model="markedContacts[key]"
              class="my-auto"
            ></el-checkbox>
          </el-row>
          <el-row
            v-for="contact in contacts"
            :key="contact?.uid"
            type="flex"
            justify="space-between"
          >
            <small
              :class="{
                marked:
                  contact?.group in markedContacts &&
                  markedContacts[contact?.group],
              }"
              >{{ contact.name }}</small
            >
            <small
              :class="{
                marked:
                  contact?.group in markedContacts &&
                  markedContacts[contact?.group],
              }"
              >{{ contact.phone }}</small
            >
          </el-row>
        </el-col>
      </el-row>
      <el-divider class="margin-divider"></el-divider>
    </div>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button type="text" class="button" @click="send">Enviar</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import { ElLoading, ElNotification } from "element-plus";
export default {
  props: ["bid", "showModal"],
  emits: ["close-modal"],
  components: { ElLoading },
  data() {
    return {
      contacts: [],
      selectAll: false,
      isLoading: true,
      markedContacts: {},
    };
  },
  mounted() {
    this.fetchContacts();
  },
  watch: {
    markedContacts: {
      handler: function (val, old) {
        console.log(val, old);
      },
      deep: true,
    },
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    SelectedContacts() {
      return this.contacts?.filter(
        (c) => c.group in this.markedContacts && this.markedContacts[c.group]
      );
    },
    ContactGrouped() {
      return this.contacts.reduce((t, e) => {
        if (!t[e.group]?.length) t[e.group] = [];

        t[e.group].push(e);
        return t;
      }, {});
    },
  },
  methods: {
    markOrUnmarkAllContacts() {
      if (this.selectAll)
        this.markedContacts = Object.keys(this.ContactGrouped).reduce(
          (t, e) => {
            t[e] = true;
            return t;
          },
          {}
        );
      else this.markedContacts = {};
    },
    send() {
      if (this.SelectedContacts?.length)
        fetch(`${this.$store.state.apiUrl}bids_sent`, {
          credentials: "include",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            bid: this.bid,
            contacts: this.SelectedContacts,
          }),
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else throw response.json();
          })
          .then(
            (e) => ElNotification.success({ title: e.message }),
            async (e) =>
              ElNotification.error({
                title: "Não foi possível criar o envio",
                message: (await e)?.message,
              })
          )
          .finally(() => (this.shouldShow = false));
      else
        ElNotification.error({
          title: "Não foi possível criar o envio",
          message: "Você deve selecionar ao menos um contato",
        });
    },
    fetchContacts() {
      const url = new URL(`${this.$store.state.apiUrl}contacts`);
      url.search = new URLSearchParams({ type: "Produtor" });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.contacts = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
  name: "SendDetails",
};
</script>
<style>
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.margin-divider {
  margin-top: 8px !important;
  margin-bottom: auto !important;
}
.marked {
  font-weight: 600;
}
</style>