<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <base-input label="Título:" v-model="bid_.title"> </base-input>
      <el-row type="flex" justify="start"><h4>Mensagem:</h4></el-row>
      <el-input
        type="textarea"
        :rows="10"
        :max="999"
        v-model="bid_.message"
        placeholder="Insira a mensagem que será enviada"
      >
      </el-input>
      <!--<el-select v-model="bid_.type" size="medium">
        <template #prefix>Funcionário:</template>
        <el-option
          v-for="item in Employees"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>-->
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";
export default {
  props: ["bid", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      bid_: this.bid || {},
      isLoadingSave: false,
      shortcuts: [
        {
          text: "Hoje",
          value: new Date(),
        },
        {
          text: "Ontem",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "Segunda-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 1)
            );
            return date;
          })(),
        },
        {
          text: "Uma semana atrás",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
    };
  },
  watch: {
    bid(v) {
      this.bid_ = v || {};
    },
  },
  computed: {
    ModalTitle() {
      return this.bid ? "Editar oferta" : "Cadastrar oferta";
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    save() {
      this.isLoadingSave = true;
      return this.bid ? this.updateBid() : this.createBid();
    },
    createBid() {
      fetch(`${this.$store.state.apiUrl}bids`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({ ...this.bid_ }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            this.shouldShow = false;
            this.bid_ = {};
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível cadastrar a oferta",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
        });
    },
    updateBid() {
      fetch(`${this.$store.state.apiUrl}bids/${this.bid_.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify(this.bid_),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível alterar a oferta",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.shouldShow = false;
        });
    },
  },
  name: "BidModal",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>