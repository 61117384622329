<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Ofertas cadastradas</h4>
        <el-col :md="15">
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="medium"
              @click="openBidModal(null)"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :data="bids"
      style="width: 100%; z-index: 0"
      :cell-style="() => 'text-align:center;'"
    >
      <el-table-column prop="title" label="título"> </el-table-column>
      <el-table-column
        prop="title"
        label="mensagem"
        :formatter="
          (r) =>
            r?.message?.slice(0, 20) + (r?.message?.length > 20 ? '...' : '')
        "
      >
      </el-table-column>
      <el-table-column label="ações" :min-width="80">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openBidModal(cat.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openSendModal(cat.row)"
              >
                <i class="el-icon-position"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteBid(cat.row)"
                title="
              Remover o anúncio?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <bid-modal
      :showModal="showBidModal"
      :bid="bid"
      @close-modal="showBidModal = false"
      @should-update="fetchBids"
    ></bid-modal>
    <send-details
      :showModal="showSendDetailsModal"
      :bid="bid"
      @close-modal="showSendDetailsModal = false"
    ></send-details>
  </el-card>
</template>

<script>
import BidModal from "./modals/BidModal.vue";
import SendDetails from "./modals/CreateBidSend.vue";

export default {
  name: "bidsPage",
  components: { BidModal, SendDetails },
  data: () => ({
    hasError: false,
    isLoading: true,
    showSendDetailsModal: false,
    bids: null,
    bid: null,
    showBidModal: false,
  }),
  mounted() {
    this.fetchBids();
  },
  methods: {
    openBidModal(e) {
      (this.bid = e), (this.showBidModal = true);
    },
    openSendModal(e) {
      (this.bid = e), (this.showSendDetailsModal = true);
    },
    deleteBid(c) {
      fetch(`${this.$store.state.apiUrl}bids/${c.uid}`, {
        credentials: "include",
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(() => {
          this.fetchBids();
          this.$notify({
            title: "Anúncio removido com sucesso.",
            type: "success",
            position: "bottom-right",
          });
        })
        .catch((e) => {
          this.$notify({
            title: "Não foi possível remover o anúncio",
            type: "error",
            message: e.message,
            position: "bottom-right",
          });
        });
    },
    fetchBids() {
      fetch(`${this.$store.state.apiUrl}bids`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.bids = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
tr.problem {
  background-color: #fbe9e7;
}
</style>